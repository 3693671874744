
import './App.css';
import React, { useState } from 'react';
import { MdDehaze } from "react-icons/md";
import { BiReset } from "react-icons/bi";



const initialValues = {
  num1: 0,
  num2: 0,
  num3:0
};



function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false)
  
 const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  

 return(
  <nav className="navBar">
    
      <button  onClick={handleToggle}>
        {navbarOpen ? (
        <MdDehaze style={{color:"white"}} /> 
        ): <MdDehaze style={{color:"white"}} />}
      </button> 
      
      <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
        
          <li><a href="#me_h2">Účtovníctvo</a></li>
          <li><a href="#sluzby">Služby</a></li>
          <li><a href="#referencie">Referencie</a></li>
          <li><a href="#cenník">Cenník</a></li>
          <li ><a href="#contact">Kontakt</a></li>
          <li><a href=" "> </a></li>
         
       
          
      </ul>
    </nav>

 ) 

};


function Filt() {

  const [values, setValues] = useState(initialValues);
  const [bill, setBill] = useState("");
  const [dph, setDph] = useState("");
  const [taxs, setTax] = useState("");
  const [typ_tax, setTyp_tax] = useState("");
  var number=(input)=>(isNaN(input)||input<0)? input===0 : input
  const [toggle, setToggle] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

   

  //Form Účtovníctvo
  function accountants(){
  var accoutns=parseInt(values.num3);
  

  switch (true){
    case bill === 'simple' && number(accoutns)<=49 && number(accoutns)>0:
        return 30;
    case bill === 'simple' && dph ==='dph' && number(accoutns)>49:
        return 35+(number(accoutns)-50)*0.58;
    case bill === 'simple' && number(accoutns)>49:
        return 30+(number(accoutns)-50)*0.46;
    case bill === 'multiple' && number(accoutns)<=49 && number(accoutns)>0:
        return 40;
    case bill === 'multiple' && dph ==='dph' && number(accoutns)>49:
          return 45+(number(accoutns)-50)*0.92;
    case bill === 'multiple' && number(accoutns)>49:
          return 40+(number(accoutns)-50)*0.81;
    default: 
        return null
          }

};

  // Form Personalistika a mzdy 
  function personal() {
    var employe = parseInt(values.num1);
                 
    return 10*number(employe);  
  };

  // Daňové priznanie
  
  
  function tax(){
    switch (true){
      case taxs === 'single' && typ_tax === 'tax_A':
          return 20;
      case taxs === 'single' && typ_tax === 'tax_B':
          return 40;
      case taxs === 'law':
          return 100;
      default: 
          return null
            }    
    }

    var [price, setPrice] =useState(0) 
    price = personal()+accountants()+ tax()
    
      const handleReset = () => {
        setSelectedOption("");
        setTyp_tax("");
        setTax(0);
        setPrice(personal()+accountants())
      };
   
    const handleTaxChange = (e) => {
      setTax('law');
      setSelectedOption(e.target.value);
      setToggle(true);
    }

    const handleTaxChange1 = (e) => {
      setTax('single');
      setSelectedOption(e.target.value);
      setToggle(false);
    }

  // Form cena spolu

   

  return (
    
                      <form>
                        <div class='intro'>
                        <p>Cena za poskytnutie ekonomických služieb sa stanovuje vždy zmluvne na základe písomnej dohody s klientom po osobnom stretnutí. <span class="bold">Uvedený formulár slúži na výpočet orientačnej </span> ceny za služby.
                          </p>
                          <p> <p>Spôsob odovzdávania a preberania dokladov je dohodnutý individuálne podľa potrieb klienta.
                                  Účtovníctvo vedieme v programoch od firmy Kros - OMEGA a OLYMP.</p></p></div>
                          
                          <div class="form_borders">
                            <div class="border_light_orange">
                              <h2 class="h2_app">Účtovníctvo</h2>
                              <p class="ucto_p">Cena je stanovená podľa počtu účtovných zápisov za mesiac.</p>
                              <p class="small_text">Pod pojmom účtovný zápis sa rozumie jeden riadok v peňažnom denníku, každý zápis v knihe pohľadávok, v knihe záväzkov, evidencii majetku alebo denníku účtovných zápisov (pri daňovej evidencii minimálne 30€ a pri podvojnom účtovníctve 40€)</p>
                        
                                <div class="radios_btn">
                                <span><input
                                  class="simple"
                                  type="radio"
                                  name="bill"
                                  value="simple"
                                  checked={bill === "simple"}
                                  onChange={() => setBill('simple')}


                                /><span class="text_size">Daňová evidencia, FO</span></span>

                                <span><input
                                  class="multiple"
                                  type="radio"
                                  name="bill"
                                  value="multiple"
                                  checked={bill === "multiple"}
                                  onChange={() => setBill('multiple')}


                                /><span class="text_size" id="multiple_account">Podvojné účtovníctvo</span></span></div>

                                <input 
                                
                                  type="checkbox" 
                                  id="dph" 
                                  name="dph" 
                                  value=""
                                  onChange={() =>(dph === "")? setDph("dph"):setDph("")}
                                  /><span class="text_size">Platca DPH</span> <br />

                                <span class="text_size_dph">Počet účtovných zápisov mesačne:</span><input
                                  class="bill_month"
                                  type="number"
                                  onChange={handleInputChange}
                                  name="num3"
                                  label="Company"
                                /> <br />
                                </div>

                      
                      
                      
                        <div class="border_orange">
                          <h2 class="h2_app">Personalistika a mzdy</h2>
                          <p class="pay">Kompletné vedenie personálnej a mzdovej agendy, vrátane ročných výkazov a ročného zúčtovania dane za jednotlivcov</p>

                          <span class="text_size"> Počet miezd:</span><input
                            class="inputs"
                            type="number"
                            onChange={handleInputChange}
                            name="num1"
                            label="Company"
                          /> 
                           </div>
                        
                
                    <div id="vat">
                      <div class="border_light_orange" >
                        
                        <button type="button"  onClick={handleReset} id="close">
                          <BiReset style={{color:"darkcyan"}} /> 
                        </button> 

                        <h2 class="h2_app" id="h2_vat">Daňové priznanie</h2>
                       
                      <span><input 
                        class="multiple"
                        type="radio"
                        name="tax"
                        value="law"
                        checked={selectedOption === "law"}
                        onChange={handleTaxChange}
                      

                      /><span class="text_size">Právnická osoba</span></span>

                      <span><input 
                        class="simple"
                        type="radio"
                        name="tax"
                        value="single"
                        checked={selectedOption === "single"}
                        onChange={handleTaxChange1}


                      /><span class="text_size">Fyzická osoba</span></span><br />

                      </div>


                      {toggle ?
                                  <></>:
                        <div class="border_white">
                            <input
                                    class="multiple"
                                    type="radio"
                                    name="tax_AB"
                                    value="tax_A"
                                    checked={typ_tax === "tax_A"}
                                    onChange={() => setTyp_tax('tax_A')}


                                  /><span class="text_size">Typ A</span>

                            <input
                                    class="multiple"
                                    type="radio"
                                    name="tax_AB"
                                    value="tax_B"
                                    checked={typ_tax === "tax_B"}
                                    onChange={() => setTyp_tax('tax_B')}


                                  /><span class="text_size">Typ B</span><br />

                            </div>
                      }
                    </div>
                      <h2 class="h2_price">Cena: {price} € </h2>
                      </div>
                      </form>
              
  );
}

export {Filt,Navbar}